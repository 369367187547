import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const InputPassword = ({
    value,
    onChange,
    className = '',
    id,
    placeholder,
    autoComplete,
}) => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => setVisible(!visible);

    return (
        <div className="flex items-center">
            <input
                type={visible ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                className={`border rounded-3xl outline-none pl-8 pr-6 py-3 flex-grow w-full hover:border-zinc-300 ${className}`}
                id={id}
                placeholder={placeholder}
                autoComplete={autoComplete}
            />
            <button
                type="button" className="outline-none text-gray-500 mr-[-30px] pl-2" onClick={toggleVisibility} >
                {visible ? (
                    <EyeSlashIcon className="h-5 w-5" />
                ) : (
                    <EyeIcon className="h-5 w-5" />
                )}
            </button>
        </div>
    );
};

export default InputPassword;
