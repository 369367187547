import React from 'react';
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline';

const NotificationItem = ({ notification, handleMarkAsRead, getDateLabel }) => {
    const { id, message, read, created_at } = notification;

    return (
        <li
            className={`flex items-start bg-white rounded-lg p-2 mx-2 shadow ${read ? 'opacity-70' : 'hover:shadow-md cursor-pointer'}`}
            onClick={() => (!read ? handleMarkAsRead(id) : null)}
        >
            <div className="flex-shrink-0">
                {!read ? (
                    <EnvelopeIcon className="w-6 h-6 text-blue-500" />
                ) : (
                    <EnvelopeOpenIcon className="w-6 h-6 text-gray-400" />
                )}
            </div>
            <div className="mr-4">
                <p className="text-sm text-gray-600 mb-1">{message}</p>
                <span className="text-xs text-gray-400">{getDateLabel(created_at)}</span>
            </div>
        </li>
    );
};

export default NotificationItem;
