import React from 'react';

const Spinner = ({ text = 'در حال اجرا...' }) => (
    <div className="flex items-center justify-center gap-2">
        <div className="w-6 h-6 border-2 border-blue-500 border-solid rounded-full border-t-transparent animate-spin"></div>
        <div>{text}</div>
    </div>
);

export default Spinner;
