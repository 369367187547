import React, { useState, useEffect, useContext, useRef } from "react";
import { PlusIcon, CheckIcon } from "@heroicons/react/24/outline";
import ColorPicker from "./ColorPicker";
import { COLORS } from "../constants";
import { AppContext } from "../contexts/AppContext";
import toast from "react-hot-toast";

const InputBox = () => {
  const [content, setContent] = useState("");
  const [editPreviewContent, setEditPreviewContent] = useState('');
  const [color, setColor] = useState(COLORS[0]);
  const {
    notes,
    getNotes,
    editNote,
    insertNote,
    selectedNote,
    date,
    onEdit,
    setOnEdit,
    isPending
  } = useContext(AppContext);
  const [newRank, setNewRank] = useState(1);
  const textareaRef = useRef(null);

  useEffect(() => {
    setNewRank(notes.length > 0 ? Math.max(...notes.map(t => t.rank)) + 1 : 1);
  }, [notes]);

  const focusTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  };

  function updateEditPreviewContent() {
    const maxCharacters = Math.floor(window.innerWidth * 0.1)
    const content = selectedNote?.content || '';
    const displayedContent = content.length > maxCharacters ? content.substring(0, maxCharacters) + '...' : content;
    setEditPreviewContent(displayedContent);
  }

  useEffect(() => {
    updateEditPreviewContent();
    window.addEventListener('resize', updateEditPreviewContent);
    return () => {
      window.removeEventListener('resize', updateEditPreviewContent);
    };
  }, [updateEditPreviewContent])

  useEffect(() => {
    if (onEdit && selectedNote) {
      setContent(selectedNote.content);
      setColor(selectedNote.color);
      focusTextarea();
    } else {
      resetInputFields();
    }
  }, [onEdit, selectedNote]);

  const resetInputFields = () => {
    setContent("");
    setOnEdit(false);
  };

  const handleEdit = async () => {
    if (!content.trim() || !selectedNote) return;
    const fields = { content: content.trim(), color };
    const response = await editNote(selectedNote.id, fields);
    if (response.status === 200) {
      toast.success(response.data);
      Object.assign(selectedNote, fields);
    }
  };

  const handleInsert = async () => {
    if (!content.trim()) return;
    const newNote = {
      content: content.trim(),
      color,
      rank: newRank,
      date: date.format("YYYY-MM-DD"),
    };
    await insertNote(newNote);
  };

  const handleAction = async () => {
    onEdit ? await handleEdit() : await handleInsert();
    resetInputFields();
    await getNotes();
  };

  const handleChange = (event) => {
    setColor(event.target.value);
  };

  const handleInputChange = (event) => {
    setContent(event.target.value);
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea();
  }, [content]);

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action to avoid a newline
      await handleAction();
    }
  };

  return (
    <div className={`fixed bg-white bottom-0 w-full border-t md:px-6 px-4 py-2 ${isPending ? 'pointer-events-none animate-pulse' : ''}`}>
      {onEdit && (
        <div className="border rounded-2xl p-2 border-dashed w-full flex items-center gap-2 mb-1 mt-[2px]">
          <button
            className="text-gray-600 hover:text-gray-800 text-xl h-6"
            onClick={resetInputFields}
          >
            ✕
          </button>
          <div className="bg-gray-400 border h-6 p-0"></div>
          <p className="text-gray-400 text-xs max-w-[80vw] caret-transparent">
            {editPreviewContent}
          </p>
        </div>
      )}
      <div className="flex items-end justify-center">
        <span className="mb-[6px] w-9 h-9 cursor-pointer">
          {onEdit ? (
            <CheckIcon
              onClick={handleAction}
            />
          ) : (
            <PlusIcon
              onClick={handleAction}
            />
          )}
        </span>
        <textarea
          ref={textareaRef}
          placeholder="یادداشت جدید"
          value={content}
          className='p-3 rounded-2xl disabled:bg-white flex-grow outline-none resize-none overflow-hidden self-center max-h-[25vh]'
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          rows={1}
          disabled={isPending}
        />
        <span className='mb-[6px]'>
          <ColorPicker selectedColor={color} onChange={handleChange} />
        </span>
      </div>
    </div>
  );
};

export default InputBox;
