import React from 'react';
import { useModal } from '../contexts/ModalContext';

const Modal = ({ id, title, children }) => {
  const { closeModal, isTopModal } = useModal();

  if (!isTopModal(id)) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={closeModal} >
      <div className="bg-white rounded-xl shadow-xl p-6 w-72 md:w-80 select-none" onClick={(e) => e.stopPropagation()} >
        <h1 className="text-center text-xl text-neutral-700 mb-6">{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default Modal;
