import { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { Calendar } from "react-multi-date-picker";
import { useState, useContext, useRef, useEffect } from "react";
import "../styles/sky.css";
import { AppContext } from "../contexts/AppContext";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";

const CalendarView = () => {
  const { date, setDate, isPending } = useContext(AppContext);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [value, setValue] = useState(new DateObject(date).convert(persian, persian_fa));
  const calendarRef = useRef(null);

  const formattedDate = value.format("DD MMMM YYYY");
  const weekDay = value.format("dddd");

  const handleCalendar = () => {
    setOpenCalendar(!openCalendar);
  };

  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    setDate(new DateObject(selectedValue).convert(gregorian, gregorian_en));
    setOpenCalendar(false);
  };

  const handleToday = () => {
    const today = new DateObject({ calendar: persian, locale: persian_fa });
    setValue(today);
    setDate(new DateObject(today).convert(gregorian, gregorian_en));
    setOpenCalendar(false);
  };

  // Close the calendar if clicking outside of it or pressing ESC key
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setOpenCalendar(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpenCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-3xl text-stone-800 font-medium cursor-default caret-transparent">{weekDay}</div>
      <div className={`flex mr-4 gap-3 text-stone-700 cursor-pointer hover:text-[#349ac8] ${isPending ? 'pointer-events-none' : ''}`} onClick={handleCalendar}>
        <CalendarIcon className="w-6" />
        <div className="text-xl caret-transparent">{formattedDate}</div>
      </div>
      {openCalendar && (
        <div ref={calendarRef} className="absolute mt-28">
          <Calendar
            calendar={persian}
            locale={persian_fa}
            className="sky"
            value={value}
            onChange={handleChange}
            monthYearSeparator="|"
          >
            <div className="flex gap-1 justify-evenly text-sm rounded-xl shadow-xl">
              <button
                className="mb-4 w-24 py-1 border text-[#ff4d4d] border-[#ff4d4d] shadow rounded-lg"
                onClick={() => {
                  setOpenCalendar(false);
                }}
              >
                انصراف
              </button>
              <button
                className="mb-4 w-24 py-1 border text-sky-400 border-sky-400 shadow rounded-lg"
                onClick={handleToday}
              >
                امروز
              </button>
            </div>
          </Calendar>
        </div>
      )}
    </div>
  );
};

export default CalendarView;
