import React from 'react';

const Input = ({
  type = 'text',
  value,
  onChange,
  className = '',
  id,
  placeholder,
  autoComplete,
}) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={`border rounded-3xl outline-none px-6 py-3 hover:border-zinc-300 ${className}`}
      id={id}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  );
};

export default Input;
