import React from 'react';
import { useModal } from '../contexts/ModalContext';

const ButtonClose = ({ children }) => {
    const { closeModal } = useModal();
    return (
        <button className="border border-zinc-400 hover:border-zinc-500 focus:bg-zinc-50 text-zinc-600 py-2 rounded-full" onClick={closeModal}>
            {children}
        </button>
    );
};

export default ButtonClose;
