import React from "react";
import CalendarView from "../components/CalendarView";
import CircularProgress from "../components/CircularProgress";
import InputBox from "../components/InputBox";
import Notes from "../components/Notes";
import { AppProvider } from "../contexts/AppContext";
import { ModalProvider } from "../contexts/ModalContext";
import ProfileModal from "../modals/ProfileModal";

const Home = () => {
  return (
    <AppProvider>
      <ModalProvider>
        <div className="fixed w-full top-0 flex justify-between py-4 px-4 md:px-6 border-b bg-white z-10">
          <CalendarView />
          <div className="flex md:gap-6 gap-4">
            <CircularProgress />
            <ProfileModal />
          </div>
        </div>
        <div className="mb-16 pb-1 mt-32">
          <Notes />
        </div>
        <InputBox />
      </ModalProvider>
    </AppProvider>
  );
};

export default Home;
