import { ArrowsRightLeftIcon, CheckIcon, DocumentDuplicateIcon, PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import toast from 'react-hot-toast';

const MenuItem = ({ icon: Icon, label, onClick, hoverBg, hoverText, roundedClass }) => {
    return (
        <li
            className={`flex items-center gap-3 px-4 py-2 cursor-pointer transition-colors duration-150 ${hoverBg} ${hoverText} ${roundedClass}`}
            onClick={onClick}
            role="menuitem"
        >            {Icon && <Icon className="w-5 h-5" />}
            <span>{label}</span>
        </li>
    );
};


const ContextMenu = ({ note, onDelete, onToggleComplete, onEdit, onOpenCalendar, position, }) => {
    return (
        <ul
            className="select-none absolute w-40 bg-white shadow-lg rounded-md text-sm border border-gray-200 transition-opacity duration-150 ease-in-out z-50"
            role="menu"
            style={{ top: position.y, left: position.x }}
        >
            <MenuItem
                icon={note.completed ? XMarkIcon : CheckIcon}
                label={note.completed ? 'گشودن' : 'بستن'}
                onClick={onToggleComplete}
                hoverBg={note.completed ? 'hover:bg-yellow-100' : 'hover:bg-green-100'}
                hoverText={note.completed ? 'hover:text-yellow-700' : 'hover:text-green-700'}
                roundedClass="rounded-t-md"
            />
            <MenuItem
                icon={DocumentDuplicateIcon}
                label="رونوشت"
                onClick={() => {navigator.clipboard.writeText(note.content); toast.success('کپی شد.')}}
                hoverBg="hover:bg-pink-100"
                hoverText="hover:text-pink-700"
            />
            <MenuItem
                icon={PencilIcon}
                label="ویرایش"
                onClick={onEdit}
                hoverBg="hover:bg-blue-100"
                hoverText="hover:text-blue-700"
            />
            <MenuItem
                icon={ArrowsRightLeftIcon}
                label="انتقال"
                onClick={onOpenCalendar}
                hoverBg="hover:bg-purple-100"
                hoverText="hover:text-purple-700"
            />
            <MenuItem
                icon={TrashIcon}
                label="حذف"
                onClick={onDelete}
                hoverBg="hover:bg-red-100"
                hoverText="hover:text-red-700"
                roundedClass="rounded-b-md"
            />
        </ul>
    );
};

export default ContextMenu;